import { MetaDataApi } from '@lereacteur/apollo-common/dist/api/MetaDataApi';
import { FirstAdminMetaData } from '@lereacteur/common/dist/routes/metadata';
import { Resource, useResource } from '@lereacteur/apollo-common/dist/hooks/useResource';

interface Props {
  metaDataApi: MetaDataApi;
}

export function FirstAdminMetaDataSlice({ metaDataApi }: Props): Resource<FirstAdminMetaData> {
  const resource = useResource({
    fetchData: metaDataApi.getInfosFirstAdminMetadata,
    name: 'FirstAdminMetaData',
  });
  return resource;
}
