import { ValidateAsyncImports, AsyncViewsType } from 'components/AsyncViewLoader';

export const ASYNC_VIEWS = {
  AcademyDetails: () => import('views/AcademyDetails').then((m) => m.AcademyDetails),
  AcademyDetailsInfos: () =>
    import('routes/AcademyDetailsInfos').then((m) => m.AcademyDetailsInfos),
  AcademyDetailsInfosEdit: () =>
    import('routes/AcademyDetailsInfosEdit').then((m) => m.AcademyDetailsInfosEdit),
  AcademyDetailsTheme: () =>
    import('routes/AcademyDetailsTheme').then((m) => m.AcademyDetailsTheme),
  AcademyDetailsThemeEdit: () =>
    import('routes/AcademyDetailsThemeEdit').then((m) => m.AcademyDetailsThemeEdit),
  AcademyTeam: () => import('routes/AcademyTeam').then((m) => m.AcademyTeam),
  AdminHome: () => import('routes/AdminHome').then((m) => m.AdminHome),
  AdminInviteUser: () => import('routes/AdminInviteUser').then((m) => m.AdminInviteUser),
  AdminLayout: () => import('views/AdminLayout').then((m) => m.AdminLayout),
  AdminNotFound: () => import('routes/AdminNotFound').then((m) => m.AdminNotFound),
  AtomEditor: () => import('views/AtomEditor').then((m) => m.AtomEditor),
  CourseDetails: () => import('views/CourseDetails').then((m) => m.CourseDetails),
  CourseDetailsEdit: () => import('routes/CourseDetailsEdit').then((m) => m.CourseDetailsEdit),
  CourseDetailsInfos: () => import('routes/CourseDetailsInfos').then((m) => m.CourseDetailsInfos),
  CourseDetailsSessions: () =>
    import('routes/CourseDetailsSessions').then((m) => m.CourseDetailsSessions),
  Courses: () => import('routes/Courses').then((m) => m.Courses),
  CreateAcademy: () => import('routes/CreateAcademy').then((m) => m.CreateAcademy),
  CreateAcademyLayout: () => import('views/CreateAcademyLayout').then((m) => m.CreateAcademyLayout),
  CreateAdmin: () => import('routes/CreateAdmin').then((m) => m.CreateAdmin),
  CreateCourse: () => import('routes/CreateCourse').then((m) => m.CourseDetailsEdit),
  CreateSession: () => import('routes/CreateSession').then((m) => m.SessionDetailsEdit),
  EntityLayout: () => import('views/EntityLayout').then((m) => m.EntityLayout),
  InviteAcademyMember: () =>
    import('routes/InviteAcademyMember').then((m) => m.InviteAcademyMember),
  InviteUser: () => import('routes/InviteUser').then((m) => m.InviteUser),
  Login: () => import('routes/Login').then((m) => m.Login),
  LoginLayout: () => import('views/LoginLayout').then((m) => m.LoginLayout),
  MarkdownEditor: () => import('views/MarkdownEditor').then((m) => m.MarkdownEditor),
  MonacoEditor: () => import('views/MonacoEditor').then((m) => m.MonacoEditor),
  PageHeader: () => import('views/PageHeader').then((m) => m.PageHeader),
  PasswordReset: () => import('routes/PasswordReset').then((m) => m.PasswordReset),
  RequestResetPassword: () =>
    import('routes/RequestResetPassword').then((m) => m.RequestResetPassword),
  SessionContent: () => import('routes/SessionContent').then((m) => m.SessionContent),
  SessionDetails: () => import('views/SessionDetails').then((m) => m.SessionDetails),
  SessionDetailsCalendar: () =>
    import('routes/SessionDetailsCalendar').then((m) => m.SessionDetailsCalendar),
  SessionDetailsChecklist: () =>
    import('routes/SessionDetailsChecklist').then((m) => m.SessionDetailsChecklist),
  SessionDetailsEdit: () => import('routes/SessionDetailsEdit').then((m) => m.SessionDetailsEdit),
  SessionDetailsInfos: () =>
    import('routes/SessionDetailsInfos').then((m) => m.SessionDetailsInfos),
  SessionDetailsStudents: () =>
    import('routes/SessionDetailsStudents').then((m) => m.SessionDetailsStudents),
  SessionDetailsAddStudents: () =>
    import('routes/SessionDetailsAddStudents').then((m) => m.SessionDetailsAddStudents),
  Sessions: () => import('routes/Sessions').then((m) => m.Sessions),
  SignupAdminFromInvite: () =>
    import('routes/SignupAdminFromInvite').then((m) => m.SignupAdminInvite),
  UserDetails: () => import('views/UserDetails').then((m) => m.UserDetails),
  UserDetailsEdit: () => import('routes/UserDetailsEdit').then((m) => m.UserDetailsEdit),
  UserDetailsInfos: () => import('routes/UserDetailsInfos').then((m) => m.UserDetailsInfos),
  UserDetailsLogin: () => import('routes/UserDetailsLogin').then((m) => m.UserDetailsLogin),
  UserDetailsLoginEdit: () =>
    import('routes/UserDetailsLoginEdit').then((m) => m.UserDetailsLoginEdit),
  UserDetailsSessions: () =>
    import('routes/UserDetailsSessions').then((m) => m.UserDetailsSessions),
  Users: () => import('routes/Users').then((m) => m.Users),
  Modules: () => import('routes/Modules').then((m) => m.Modules),
} as const;

/**
 * Types (DON'T CHANGE IT !)
 */

export type AppAsyncViewsImports = typeof ASYNC_VIEWS;
export type AppAsyncViewName = keyof AppAsyncViewsImports;
export type AppAsyncViewsValue = {
  [K in AppAsyncViewName]: ReturnType<AppAsyncViewsImports[K]> extends Promise<infer R> ? R : any;
}[AppAsyncViewName];
// Unsed only to make sure ASYNC_VIEWS has valid type
export type Valid = ValidateAsyncImports<AppAsyncViewsImports>;
export type AppAsyncViewsType = AsyncViewsType<AppAsyncViewsImports>;
export type AppAsyncViewAny = AppAsyncViewsType[keyof AppAsyncViewsType];
