import { ExtractMatch } from '@lereacteur/apollo-common/dist/router/RoutePattern';
import { ADMIN_APP_ROUTES } from '@lereacteur/common/dist/constants/ADMIN_APP_ROUTES';
import { AppCore } from 'logic/Core';
import { AppSliceState } from 'logic/slices/AppSlice';
import queryString from 'query-string';
import { UserRole } from '@lereacteur/common/dist/constants/enums';
import {
  AsyncViewStatus,
  AsyncViewState,
  isAsyncViewState,
  AsyncViewsStoreState,
} from 'logic/slices/AsyncViewsSlice';
import { Resource, isResource } from '@lereacteur/apollo-common/dist/hooks/useResource';
import { SelectManagerContext } from '@lereacteur/apollo-common/dist/connect/SelectManager';
import { Me } from '@lereacteur/common/dist/routes/auth';
import { SessionTree, SessionTreeItem } from '@lereacteur/common/dist/routes/session';
import { Atom } from '@lereacteur/common/dist/routes/atom';

type Dependency = AsyncViewState | Resource<any>;
type Dependencies = Array<Dependency>;
export type DependenciesObj = { required: Dependencies; lazy: Dependencies };
export type DependenciesResult = Dependencies | DependenciesObj | null;

type Command =
  | { type: 'Dependencies'; deps: DependenciesObj }
  | { type: 'Redirect'; to: string; effect?: () => void }
  | null;

const Commands = {
  load(deps: DependenciesResult): Command {
    return { type: 'Dependencies', deps: cleanupDependenciesObject(deps) };
  },
  redirect(to: string, effect?: () => void): Command {
    return { type: 'Redirect', to, effect };
  },
};

export function createStabilizer(
  stabilizerSelectCtx: SelectManagerContext,
  getCore: () => AppCore
): (state: AppSliceState) => void {
  let prevResourcesDeps: Array<Resource<any>> = [];

  let redirectStack: Array<string> = [];
  let requestedRedirectStack: Array<string> = [];

  return (state) => {
    const { selectors } = getCore();

    const navigation = state.navigation;
    const currentRoutes = stabilizerSelectCtx.execute(selectors.selectAllRoutesMatch, state);

    const currentRouteCommand = stabilizeRoute(state, currentRoutes);
    if (currentRouteCommand === null) {
      // What ?
      return;
    }
    if (currentRouteCommand.type === 'Redirect') {
      if (redirectStack.length > 50) {
        console.log('STOP');
        console.log(redirectStack);
        throw new Error('Too many redirect');
      }
      if (currentRouteCommand.effect) {
        currentRouteCommand.effect();
      }
      navigation.redirect(currentRouteCommand.to);
      redirectStack.push(currentRouteCommand.to);
      return;
    }
    redirectStack = [];
    const currentDeps = currentRouteCommand.deps;
    const requestedRoutes = stabilizerSelectCtx.execute(selectors.selectAllRoutesRequested, state);
    const requestedRouteCommand = stabilizeRoute(state, requestedRoutes);
    if (requestedRouteCommand && requestedRouteCommand.type === 'Redirect') {
      if (requestedRedirectStack.length > 50) {
        console.log(requestedRedirectStack);
        throw new Error('Too many redirect in requested');
      }
      if (requestedRouteCommand.effect) {
        requestedRouteCommand.effect();
      }
      console.log(`Redirect to ${requestedRouteCommand.to}`);
      // replace requested with redirect
      navigation.navigate(requestedRouteCommand.to);
      requestedRedirectStack.push(requestedRouteCommand.to);
    } else {
      requestedRedirectStack = [];
    }
    const requestedDeps: DependenciesObj =
      requestedRouteCommand && requestedRouteCommand.type === 'Dependencies'
        ? requestedRouteCommand.deps
        : { required: [], lazy: [] };

    // combine all (lazy are only loaded for the current route)
    const nextDeps = [...currentDeps.required, ...currentDeps.lazy, ...requestedDeps.required];

    // find all async view and request them (no-op if already requested)
    const nextAsyncViewsDeps = nextDeps.filter(isAsyncViewState);
    nextAsyncViewsDeps.forEach((dep) => {
      state.asyncViews.request(dep.name);
    });

    // find all resources
    const nextResourcesDeps = nextDeps.filter(isResource);

    // flag resources we don't need anymore
    prevResourcesDeps.forEach((res) => {
      const stillInDeps = nextResourcesDeps.find((d) => d.sliceId === res.sliceId);
      if (!stillInDeps) {
        res.setRequested(false);
      }
    });

    // request new resources
    nextResourcesDeps.forEach((res) => {
      const alreadyInDeps = prevResourcesDeps.find((d) => d.sliceId === res.sliceId);
      if (!alreadyInDeps) {
        res.setRequested(true);
      }
    });

    // store resources for cleanup
    prevResourcesDeps = nextResourcesDeps;

    const currentAllResolved = currentDeps.required.every(dependencyResolved);

    // load icons when everything else is loaded !
    if (currentAllResolved && state.icons.requested === false) {
      state.icons.load();
    }

    // handle navigation requested
    if (state.navigation.requested) {
      if (state.unsavedWarningVisible) {
        // do not apply navigation
        return;
      }
      if (state.navigation.requestedTimeout) {
        navigation.applyRequestedLocation();
        return;
      }
      const nextRequiredAllResolved = requestedDeps.required.every(dependencyResolved);
      if (nextRequiredAllResolved) {
        navigation.applyRequestedLocation();
        return;
      }
    }
  };
}

function stabilizeRoute(
  state: AppSliceState,
  routes: ExtractMatch<typeof ADMIN_APP_ROUTES> | null
): Command {
  const views = state.asyncViews.state;

  if (routes === null) {
    return null;
  }

  if (routes.adminHome) {
    return authenticatedRoute(state, [views.AdminLayout, views.AdminHome], () => {
      // Redirect admin home to sessions
      return Commands.redirect(ADMIN_APP_ROUTES.sessions.serialize());
    });
  }

  if (routes.createAdmin) {
    return maybeAuthenticatedRoute(state, [views.LoginLayout, views.CreateAdmin], (maybeMe) => {
      if (maybeMe) {
        if (maybeMe.isSingleAdminNoAcademy) {
          return Commands.redirect(ADMIN_APP_ROUTES.createAcademy.serialize());
        }
        return Commands.redirect(ADMIN_APP_ROUTES.adminHome.serialize());
      }
      const firstAdminMetaDataRes = state.firstAdminMetaData;
      const firstAdminMetaData = firstAdminMetaDataRes.dataOrNull;
      if (firstAdminMetaDataRes.stable && firstAdminMetaData) {
        if (firstAdminMetaData.adminCreated) {
          if (firstAdminMetaData.academyCreated) {
            return Commands.redirect(ADMIN_APP_ROUTES.adminHome.serialize());
          }
          return Commands.redirect(ADMIN_APP_ROUTES.createAcademy.serialize());
        }
      }
      return Commands.load([firstAdminMetaDataRes]);
    });
  }

  if (routes.login) {
    return unauthenticatedRoute(state, [views.LoginLayout, views.Login]);
  }

  if (routes.requestResetPassword) {
    return unauthenticatedRoute(state, [views.LoginLayout, views.RequestResetPassword]);
  }

  if (routes.resetPassword) {
    return unauthenticatedRoute(state, [views.PasswordReset, views.LoginLayout]);
  }

  // intercept the token in query and redirect to login
  if (routes.loginWithGithubToken) {
    return unauthenticatedRoute(state, [], () => {
      const search = state.location.search;
      const params = queryString.parse(search);
      const token = params.token;
      if (token && typeof token === 'string') {
        return Commands.redirect(ADMIN_APP_ROUTES.login.serialize(), () => {
          console.log('set token');
          state.setToken(token);
        });
      }
      return Commands.redirect(ADMIN_APP_ROUTES.login.serialize());
    });
  }

  if (routes.signupAdminFromInvite) {
    return unauthenticatedRoute(state, [views.SignupAdminFromInvite, views.LoginLayout]);
  }

  if (routes.createAcademy) {
    return maybeAuthenticatedRoute(
      state,
      [views.CreateAcademy, views.CreateAcademyLayout],
      (maybeMe) => {
        if (!maybeMe) {
          return Commands.redirect(ADMIN_APP_ROUTES.login.serialize());
        }
        if (!maybeMe.isSingleAdminNoAcademy) {
          return Commands.redirect(ADMIN_APP_ROUTES.adminHome.serialize());
        }
        return null;
      }
    );
  }

  if (routes.academyInfos) {
    return authenticatedRoute(
      state,
      [
        views.AcademyDetailsInfos,
        views.AcademyDetails,
        views.AdminLayout,
        views.EntityLayout,
        views.PageHeader,
      ],
      () => {
        return Commands.load([state.academy]);
      }
    );
  }

  if (routes.academyInfosEdit) {
    return authenticatedRoute(
      state,
      [
        views.AcademyDetailsInfosEdit,
        views.AcademyDetails,
        views.AdminLayout,
        views.EntityLayout,
        views.PageHeader,
      ],
      () => {
        return Commands.load([state.academy]);
      }
    );
  }

  if (routes.academyDetailsTheme) {
    return authenticatedRoute(
      state,
      [
        views.AcademyDetailsTheme,
        views.AcademyDetails,
        views.AdminLayout,
        views.EntityLayout,
        views.PageHeader,
      ],
      () => {
        return Commands.load([state.academy]);
      }
    );
  }

  if (routes.academyDetailsThemeEdit) {
    return authenticatedRoute(
      state,
      [
        views.AcademyDetailsThemeEdit,
        views.AcademyDetails,
        views.AdminLayout,
        views.EntityLayout,
        views.PageHeader,
      ],
      () => {
        return Commands.load([state.academy]);
      }
    );
  }

  if (routes.academyTeam) {
    return authenticatedRoute(
      state,
      [
        views.AcademyTeam,
        views.AcademyDetails,
        views.AdminLayout,
        views.EntityLayout,
        views.PageHeader,
      ],
      () => {
        return Commands.load([state.academy, state.academyTeamTable]);
      }
    );
  }

  if (routes.academyMemberInvite) {
    return authenticatedRoute(state, [
      views.InviteAcademyMember,
      views.EntityLayout,
      views.AdminLayout,
    ]);
  }

  if (routes.courses) {
    return authenticatedRoute(state, [views.AdminLayout, views.Courses, views.EntityLayout], () => {
      return Commands.load([state.coursesTable]);
    });
  }

  if (routes.createCourse) {
    return authenticatedRoute(state, [
      views.AdminLayout,
      views.CreateCourse,
      views.CourseDetails,
      views.EntityLayout,
      views.PageHeader,
    ]);
  }

  if (routes.courseInfos) {
    const { courseId } = routes.courseInfos;
    return authenticatedRoute(
      state,
      [
        views.CourseDetailsInfos,
        views.CourseDetails,
        views.AdminLayout,
        views.EntityLayout,
        views.PageHeader,
      ],
      () => {
        const courseRes = state.courseMap.getOrVoid(courseId);
        return Commands.load([courseRes]);
      }
    );
  }

  if (routes.courseSessionsList) {
    const { courseId } = routes.courseSessionsList;
    return authenticatedRoute(
      state,
      [
        views.CourseDetailsSessions,
        views.CourseDetails,
        views.AdminLayout,
        views.EntityLayout,
        views.PageHeader,
      ],
      () => {
        const courseRes = state.courseMap.getOrVoid(courseId);
        const sessionsRes = state.courseMapSessions.getOrVoid(courseId);
        return Commands.load([courseRes, sessionsRes]);
      }
    );
  }

  if (routes.courseEdit) {
    const { courseId } = routes.courseEdit;
    return authenticatedRoute(
      state,
      [
        views.CourseDetailsEdit,
        views.CourseDetails,
        views.AdminLayout,
        views.EntityLayout,
        views.PageHeader,
      ],
      () => {
        const courseRes = state.courseMap.getOrVoid(courseId);
        return Commands.load([courseRes]);
      }
    );
  }

  if (routes.users) {
    return authenticatedRoute(state, [views.AdminLayout, views.Users, views.EntityLayout], () => {
      return Commands.load([state.usersTable]);
    });
  }

  if (routes.usersInvite) {
    return authenticatedRoute(state, [views.AdminLayout, views.InviteUser, views.EntityLayout]);
  }

  if (routes.userInfos) {
    const { userId } = routes.userInfos;
    return authenticatedRoute(
      state,
      [
        views.AdminLayout,
        views.UserDetailsInfos,
        views.UserDetails,
        views.EntityLayout,
        views.PageHeader,
      ],
      () => {
        const currentUser = state.userMap.getOrVoid(userId);
        return Commands.load([currentUser]);
      }
    );
  }

  if (routes.userInfosLogin) {
    const { userId } = routes.userInfosLogin;
    return authenticatedRoute(
      state,
      [
        views.UserDetailsLogin,
        views.UserDetails,
        views.AdminLayout,
        views.EntityLayout,
        views.PageHeader,
      ],
      () => {
        const currentUser = state.userMap.getOrVoid(userId);
        return Commands.load([currentUser]);
      }
    );
  }

  if (routes.userEdit) {
    const { userId } = routes.userEdit;
    return authenticatedRoute(
      state,
      [
        views.AdminLayout,
        views.UserDetails,
        views.EntityLayout,
        views.PageHeader,
        views.UserDetailsEdit,
      ],
      () => {
        const currentUser = state.userMap.getOrVoid(userId);
        return Commands.load([currentUser]);
      }
    );
  }

  if (routes.userLoginEdit) {
    const { userId } = routes.userLoginEdit;
    return authenticatedRoute(
      state,
      [
        views.UserDetailsLoginEdit,
        views.UserDetails,
        views.AdminLayout,
        views.EntityLayout,
        views.PageHeader,
      ],
      () => {
        const currentUser = state.userMap.getOrVoid(userId);
        return Commands.load([currentUser]);
      }
    );
  }

  if (routes.userSessions) {
    const { userId } = routes.userSessions;
    return authenticatedRoute(
      state,
      [
        views.AdminLayout,
        views.UserDetailsSessions,
        views.UserDetails,
        views.EntityLayout,
        views.PageHeader,
      ],
      () => {
        const currentUser = state.userMap.getOrVoid(userId);
        const currentUserSessions = state.userMapSessionsTables.getOrVoid(userId);

        return Commands.load([currentUser, currentUserSessions, state.sessionsTable]);
      }
    );
  }

  if (routes.sessions) {
    return authenticatedRoute(
      state,
      [views.AdminLayout, views.Sessions, views.EntityLayout],
      () => {
        return Commands.load([state.sessionsTable]);
      }
    );
  }

  if (routes.sessionInfos) {
    const { sessionId } = routes.sessionInfos;
    return authenticatedRoute(
      state,
      [
        views.AdminLayout,
        views.SessionDetailsInfos,
        views.SessionDetails,
        views.EntityLayout,
        views.PageHeader,
      ],
      () => {
        const sessionsRes = state.sessionMap.getOrVoid(sessionId);
        return Commands.load([sessionsRes]);
      }
    );
  }

  if (routes.sessionEdit) {
    const { sessionId } = routes.sessionEdit;
    return authenticatedRoute(
      state,
      [
        views.AdminLayout,
        views.SessionDetailsEdit,
        views.SessionDetails,
        views.EntityLayout,
        views.PageHeader,
      ],
      () => {
        const sessionsRes = state.sessionMap.getOrVoid(sessionId);
        return Commands.load([sessionsRes, state.createSessionMetaData]);
      }
    );
  }

  if (routes.sessionChecklist) {
    const { sessionId } = routes.sessionChecklist;
    return authenticatedRoute(
      state,
      [
        views.AdminLayout,
        views.SessionDetailsChecklist,
        views.SessionDetails,
        views.EntityLayout,
        views.PageHeader,
      ],
      () => {
        const sessionsRes = state.sessionMap.getOrVoid(sessionId);
        return Commands.load([sessionsRes]);
      }
    );
  }

  if (routes.sessionCalendar) {
    const { sessionId } = routes.sessionCalendar;
    return authenticatedRoute(
      state,
      [
        views.AdminLayout,
        views.SessionDetailsCalendar,
        views.SessionDetails,
        views.EntityLayout,
        views.PageHeader,
      ],
      () => {
        const sessionsRes = state.sessionMap.getOrVoid(sessionId);
        return Commands.load([sessionsRes]);
      }
    );
  }

  if (routes.sessionStudents) {
    const { sessionId } = routes.sessionStudents;
    return authenticatedRoute(
      state,
      [
        views.AdminLayout,
        views.SessionDetailsStudents,
        views.SessionDetails,
        views.EntityLayout,
        views.PageHeader,
      ],
      () => {
        const sessionsRes = state.sessionMap.getOrVoid(sessionId);
        return Commands.load([sessionsRes]);
      }
    );
  }

  if (routes.sessionContent) {
    const { sessionId } = routes.sessionContent;
    return authenticatedRoute(state, [views.SessionContent, views.AdminLayout], () => {
      const sessionRes = state.sessionMap.getOrVoid(sessionId);
      const sessionTreeRes = state.sessionTreeMap.getOrVoid(sessionId);
      return Commands.load([sessionRes, sessionTreeRes]);
    });
  }

  if (routes.sessionContentAtom) {
    const { sessionId, atomId } = routes.sessionContentAtom;
    return authenticatedRoute(
      state,
      [views.SessionContent, views.AdminLayout, views.AtomEditor],
      () => {
        const sessionRes = state.sessionMap.getOrVoid(sessionId);
        const sessionTreeRes = state.sessionTreeMap.getOrVoid(sessionId);
        const sessionTree = sessionTreeRes.dataOrNull;
        const deps: Dependencies = [];
        if (sessionTree) {
          // Make sure atom is in tree
          const sessionTreeItem = findInSessionTree(sessionTree, atomId);
          if (sessionTreeItem === null) {
            return Commands.redirect(ADMIN_APP_ROUTES.sessionContent.serialize({ sessionId }));
          }
          const atomRes = state.atomMap.getOrVoid(atomId);
          deps.push(atomRes);
          const atom = atomRes.dataOrNull;
          if (atom) {
            deps.push(...getAtomView(views, atom));
          }
        }
        return Commands.load([sessionRes, sessionTreeRes, ...deps]);
      }
    );
  }

  if (routes.createSession) {
    return authenticatedRoute(
      state,
      [
        views.AdminLayout,
        views.CreateSession,
        views.SessionDetails,
        views.EntityLayout,
        views.PageHeader,
      ],
      () => {
        return Commands.load([state.createSessionMetaData]);
      }
    );
  }

  if (routes.sessionAddStudents) {
    const { sessionId } = routes.sessionAddStudents;
    return authenticatedRoute(
      state,
      [
        views.SessionDetailsAddStudents,
        views.AdminLayout,
        views.SessionDetails,
        views.EntityLayout,
        views.PageHeader,
      ],
      () => {
        return Commands.load([state.sessionMap.getOrVoid(sessionId)]);
      }
    );
  }

  if (routes.sessionInviteUser) {
    const { sessionId } = routes.sessionInviteUser;
    return authenticatedRoute(
      state,
      [views.AdminInviteUser, views.AdminLayout, views.EntityLayout],
      () => {
        return Commands.load([state.sessionMap.getOrVoid(sessionId)]);
      }
    );
  }

  if (routes.modules) {
    return authenticatedRoute(state, [views.AdminLayout, views.Modules], () => {
      return Commands.load([]);
    });
  }

  if (Object.values(routes).every((v) => v === false)) {
    return Commands.load([views.AdminLayout, views.AdminNotFound]);
  }

  console.warn('Route not handled', routes);
  return Commands.load([views.AdminLayout, views.AdminNotFound]);
}

function dependencyResolved(dep: Dependency): boolean {
  if (isResource(dep)) {
    return dep.resource.status !== 'void' && dep.stable;
  }
  if (isAsyncViewState(dep)) {
    return dep.status === AsyncViewStatus.RESOLVED || dep.status === AsyncViewStatus.REJECTED;
  }
  console.warn('Unhandled dep', dep);
  return true;
}

function cleanupDependenciesObject(deps: DependenciesResult): DependenciesObj {
  // Remove duplicate
  const deduped: DependenciesObj = { required: [], lazy: [] };
  const obj: DependenciesObj =
    deps === null
      ? { required: [], lazy: [] }
      : Array.isArray(deps)
      ? { required: deps, lazy: [] }
      : deps;
  const alreadyAddedKeys = new Set<string>();
  obj.required.forEach((dep) => {
    const key = getDependencyKey(dep);
    if (!alreadyAddedKeys.has(key)) {
      alreadyAddedKeys.add(key);
      deduped.required.push(dep);
    }
  });
  obj.lazy.forEach((dep) => {
    const key = getDependencyKey(dep);
    if (!alreadyAddedKeys.has(key)) {
      alreadyAddedKeys.add(key);
      deduped.lazy.push(dep);
    }
  });
  return deduped;
}

function getDependencyKey(dep: Dependency): string {
  if (isResource(dep)) {
    return dep.sliceId;
  }
  if (isAsyncViewState(dep)) {
    return `AsyncViewsSlice-${dep.name}`;
  }
  console.warn(dep);
  throw new Error(`Unhandled dep`);
}

function maybeAuthenticatedRoute(
  state: AppSliceState,
  views: DependenciesResult,
  exec?: (user: Me | null) => Command
) {
  if (state.token === null) {
    const sub = exec ? exec(null) : null;
    if (sub === null) {
      return Commands.load(views);
    }
    if (sub.type === 'Redirect') {
      return sub;
    }
    return Commands.load(mergeDependenciesObj(sub.deps, cleanupDependenciesObject(views)));
  }
  const meUser = state.me.dataOrNull;
  const meStable = state.me.stable;

  if (meStable === false) {
    return Commands.load(views);
  }
  if (meUser === null) {
    // meUser failed => redirect to login
    return Commands.redirect(ADMIN_APP_ROUTES.login.serialize(), () => {
      state.logout();
    });
  }
  if (meUser.role !== UserRole.Values.admin) {
    return Commands.redirect(ADMIN_APP_ROUTES.login.serialize(), () => {
      state.logout();
    });
  }

  const sub = exec ? exec(meUser) : null;
  if (sub === null) {
    return Commands.load(views);
  }
  if (sub.type === 'Redirect') {
    return sub;
  }
  return Commands.load(mergeDependenciesObj(sub.deps, cleanupDependenciesObject(views)));
}

function unauthenticatedRoute(
  state: AppSliceState,
  views: DependenciesResult,
  exec?: () => Command
): Command {
  if (state.token !== null) {
    return Commands.redirect(ADMIN_APP_ROUTES.adminHome.serialize());
  }
  const sub = exec ? exec() : null;
  if (sub === null) {
    return Commands.load(views);
  }
  if (sub.type === 'Redirect') {
    return sub;
  }
  return Commands.load(mergeDependenciesObj(sub.deps, cleanupDependenciesObject(views)));
}

function authenticatedRoute(
  state: AppSliceState,
  views: DependenciesResult,
  whenAuth?: (user: Me) => Command
): Command {
  if (state.token === null) {
    return Commands.redirect(ADMIN_APP_ROUTES.login.serialize());
  }
  const meUser = state.me.dataOrNull;
  const meStable = state.me.stable;

  if (meStable === false) {
    return Commands.load(views);
  }
  if (meUser === null) {
    // meUser failed => redirect to login
    return Commands.redirect(ADMIN_APP_ROUTES.login.serialize(), () => {
      state.logout();
    });
  }
  if (meUser.isSingleAdminNoAcademy) {
    return Commands.redirect(ADMIN_APP_ROUTES.createAcademy.serialize());
  }
  if (meUser.role !== UserRole.Values.admin) {
    return Commands.redirect(ADMIN_APP_ROUTES.login.serialize(), () => {
      state.logout();
    });
  }
  const sub = whenAuth ? whenAuth(meUser) : null;
  if (sub === null) {
    return Commands.load(views);
  }
  if (sub.type === 'Redirect') {
    return sub;
  }
  return Commands.load(mergeDependenciesObj(sub.deps, cleanupDependenciesObject(views)));
}

function mergeDependenciesObj(left: DependenciesObj, right: DependenciesObj): DependenciesObj {
  return cleanupDependenciesObject({
    lazy: [...left.lazy, ...right.lazy],
    required: [...left.required, ...right.required],
  });
}

function findInSessionTree(sessionTree: SessionTree, atomId: string): SessionTreeItem | null {
  if (sessionTree === null) {
    return null;
  }
  for (const child of sessionTree.children) {
    const item = findInSessionTreeItem(child, atomId);
    if (item) {
      return item;
    }
  }
  return null;
}

function findInSessionTreeItem(item: SessionTreeItem, atomId: string): SessionTreeItem | null {
  if (item._id === atomId) {
    return item;
  }
  for (const child of item.children) {
    const item = findInSessionTreeItem(child, atomId);
    if (item) {
      return item;
    }
  }
  return null;
}

function getAtomView(views: AsyncViewsStoreState, atom: Atom): Dependencies {
  if (atom.type === 'Root') {
    console.warn('Cannot render Root');
    return [];
  }
  if (atom.type === 'Folder') {
    return [];
  }
  if (atom.type === 'MarkdownFile') {
    return [views.MarkdownEditor, views.MonacoEditor];
  }
  if (atom.type === 'MarkdownFolder') {
    return [views.MarkdownEditor, views.MonacoEditor];
  }
  console.warn(`Unhandled atom type !`);
  return [];
}
