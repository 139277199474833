import { useChildren, useLayoutEffect, useCallback } from 'democrat';
import { SessionApi } from '@lereacteur/apollo-common/dist/api/SessionApi';
import { RealtimeClientManager } from 'logic/RealtimeClientManager';
import { SessionTree } from '@lereacteur/common/dist/routes/session';
import { useLatestRef } from 'logic/hooks/useLatestRef';
import { createResourceMapSlice } from '@lereacteur/apollo-common/dist/slices/ResourceMapSlice';

interface Props {
  sessionApi: SessionApi;
  realtime: RealtimeClientManager;
}

export function SessionTreeMapSlice({ sessionApi, realtime }: Props) {
  const fetchData = useCallback(
    (id: string) => {
      return sessionApi.getTreeById(id, { includeDrafts: true, includeTrash: true });
    },
    [sessionApi]
  );

  const sessionsTree = useChildren(
    createResourceMapSlice<string, SessionTree>({
      name: 'Session Tree',
      fetchData,
    })
  );

  const sessionsTreeRef = useLatestRef(sessionsTree);

  useLayoutEffect(() => {
    return realtime.subscribe((message) => {
      if (message.type === 'OudateSessionTree') {
        const res = sessionsTreeRef.current.resources.get(message.sessionId);
        if (res && res.requested) {
          res.outdate();
        }
      }
    });
  }, [realtime, sessionsTreeRef]);

  return sessionsTree;
}
