import { MetaDataApi } from '@lereacteur/apollo-common/dist/api/MetaDataApi';
import { CreateSessionMetaData } from '@lereacteur/common/dist/routes/metadata';
import { Resource, useResource } from '@lereacteur/apollo-common/dist/hooks/useResource';
import { useLatestRef } from '../hooks/useLatestRef';
import { RealtimeClientManager } from 'logic/RealtimeClientManager';
import { useLayoutEffect } from 'democrat';

interface Props {
  metaDataApi: MetaDataApi;
  realtime: RealtimeClientManager;
}

export function CreateSessionMetaDataSlice({
  metaDataApi,
  realtime,
}: Props): Resource<CreateSessionMetaData> {
  const resource = useResource({
    fetchData: metaDataApi.getSessionFormMetadata,
    name: 'CreateSessionMetaData',
  });

  const resourceRef = useLatestRef(resource);

  useLayoutEffect(() => {
    return realtime.subscribeOutdate((routes) => {
      if (routes.course) {
        const res = resourceRef.current;
        if (res.requested) {
          res.outdate();
        }
      }
    });
  }, [resourceRef, realtime]);

  return resource;
}
