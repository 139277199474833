import { createResourceMapWithParamsSlice } from '@lereacteur/apollo-common/dist/slices/ResourceMapWithParamsSlice';
import { useChildren, useLayoutEffect } from 'democrat';
import { SessionApi } from '@lereacteur/apollo-common/dist/api/SessionApi';
import { RealtimeClientManager } from 'logic/RealtimeClientManager';
import { useLatestRef } from '../hooks/useLatestRef';
import { SessionsListParams, SessionsListResult } from '@lereacteur/common/dist/routes/session';
import { useCallback } from 'react';

interface Props {
  sessionApi: SessionApi;
  realtime: RealtimeClientManager;
}

export function UserMapSessionsSlice({ sessionApi, realtime }: Props) {
  const sessionListWithUserId = useCallback(
    (id: string, params: Required<SessionsListParams>) =>
      sessionApi.list({ ...params, userId: id }),
    [sessionApi]
  );

  const sessions = useChildren(
    createResourceMapWithParamsSlice<string, Required<SessionsListParams>, SessionsListResult>({
      fetchData: sessionListWithUserId,
      name: 'UserSessionList',
      initialParams: {
        itemsPerPage: 50,
        pageIndex: 0,
        sort: null,
        order: null,
        courseId: null,
        userId: null,
        startAt: null,
        endAt: null,
        search: '',
        reference: null,
      },
    })
  );

  const usersRef = useLatestRef(sessions);

  useLayoutEffect(() => {
    return realtime.subscribeOutdate((routes: any) => {
      if (routes.user) {
        const res = usersRef.current.resources.get(routes.user.id);
        if (res && res.requested) {
          res.outdate();
        }
      }
    });
  }, [usersRef, realtime]);

  return sessions;
}
