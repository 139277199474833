import { AcademyApi } from '@lereacteur/apollo-common/dist/api/AcademyApi';
import { AcademyInfos } from '@lereacteur/common/dist/routes/academy';
import { Resource, useResource } from '@lereacteur/apollo-common/dist/hooks/useResource';
import { RealtimeClientManager } from 'logic/RealtimeClientManager';
import { useLayoutEffect } from 'democrat';
import { useLatestRef } from 'logic/hooks/useLatestRef';

interface Props {
  academyApi: AcademyApi;
  realtime: RealtimeClientManager;
}

export function AcademySlice({ academyApi, realtime }: Props): Resource<AcademyInfos> {
  const resource = useResource({ fetchData: academyApi.getInfos, name: 'Academy' });
  const academyRef = useLatestRef(resource);

  useLayoutEffect(() => {
    return realtime.subscribeOutdate((routes) => {
      if (routes.academy) {
        if (academyRef.current.requested) {
          academyRef.current.outdate();
        }
      }
    });
  }, [academyRef, realtime]);

  return resource;
}
