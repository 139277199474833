import { Envs } from 'logic/Envs';
import { Fonts, theme } from '@lereacteur/apollo-common/dist/style';

// const globalFont = Fonts.AvenirNext.styles.Regular.Normal;
const globalFont = Fonts.Averta.styles.Regular;

export const globalStyle = [
  Fonts.AvenirNext.fontFaces,
  Fonts.FiraCode.fontFaces,
  Fonts.Averta.fontFaces,
  {
    'html, body': {
      height: '100%',
      width: '100%',
      margin: '0',
      padding: '0',
      ...globalFont,
      fontFamily:
        globalFont.fontFamily +
        ", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',\n      'Droid Sans', 'Helvetica Neue', sans-serif",
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      background: theme.aliases.global.backgroundColor,
      color: theme.aliases.global.text.body,
      fontSize: 14,
    },
    body: {
      height: '100%',
      width: '100%',
    },
    '#root': {
      height: '100%',
      width: '100%',
      padding: '0.05px',
    },
    '*': {
      boxSizing: 'border-box',
    },
    ':focus': {
      outline: 'rgba(#137cbd, 0.6) auto 2px',
      outlineOffset: '2px',
      MozOutlineRadius: '6px',
    },
    // make native scrollbar ugly so we don't use them !
    // we should use <Scrollbars> instead
    ...(Envs.IS_DEV
      ? {
          '::-webkit-scrollbar': {
            backgroundColor: 'transparent',
          },
          '::-webkit-scrollbar-track': {
            backgroundColor: 'red',
          },
          '::-webkit-scrollbar-thumb': {
            backgroundColor: 'blue',
            borderRadius: '5px',
          },
        }
      : {}),
  },
];
