import { SessionFormat } from '@lereacteur/common/dist/constants/enums';

// fake i18n function to identify translation in the app
export function t(str: string): string {
  return str;
}

export const SESSION_FORMAT_OPTIONS: Array<{
  label: string;
  value: SessionFormat | string;
}> = [
  {
    value: SessionFormat.Values['full-time'],
    label: t('En présentiel'),
  },
  {
    value: SessionFormat.Values.online,
    label: t('En ligne'),
  },
  {
    value: SessionFormat.Values['part-time'],
    label: t('En temps partiel'),
  },
];
