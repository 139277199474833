import { AcademyApi } from '@lereacteur/apollo-common/dist/api/AcademyApi';
import { AcademyPublicInfos } from '@lereacteur/common/dist/routes/academy';
import { Resource, useResource } from '@lereacteur/apollo-common/dist/hooks/useResource';

interface Props {
  academyApi: AcademyApi;
}

export function AcademyPublicSlice({ academyApi }: Props): Resource<AcademyPublicInfos> {
  const resource = useResource({ fetchData: academyApi.publicInfos, name: 'AcademyPublicInfos' });
  return resource;
}
