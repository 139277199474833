import 'style/inject';
import { AppCore } from 'logic/Core';
import { Router } from '../Router';
import { Global } from '@emotion/react';
import { globalStyle } from 'style/global';
import { UnsavedWarning } from 'views/UnsavedWarning';
import { CoreContext } from 'logic/contexts/CoreContext';
import { IconsProvider } from 'logic/contexts/IconsProvider';
import { WindowSizeProvider } from '@lereacteur/apollo-common/dist/components/WindowSize';
import { LayoutSizeProvider } from '@lereacteur/apollo-common/dist/components/LayoutSize';
import { AtomCacheProvider } from '@lereacteur/apollo-common/dist/components/AtomCache';
import { OverlayProvider } from '@lereacteur/apollo-common/dist/components/Overlay';
import { ThemeProvider, theme } from '@lereacteur/apollo-common/dist/style';
import { memo, Fragment, StrictMode } from 'react';

type Props = {
  core: AppCore;
};

export const Root: React.FC<Props> = memo<Props>(function Root({ core }) {
  const strictMode = false;
  const Wrapper = strictMode ? StrictMode : Fragment;
  const ConnectProvider = core.connect.Provider;

  return (
    <Wrapper>
      <WindowSizeProvider>
        <LayoutSizeProvider>
          <ThemeProvider theme={theme}>
            <ConnectProvider store={core.getStore()} select={core.selectManager}>
              <CoreContext.Provider value={core}>
                <IconsProvider>
                  <AtomCacheProvider>
                    <OverlayProvider>
                      <Global styles={globalStyle as any} />
                      <UnsavedWarning />
                      <Router />
                    </OverlayProvider>
                  </AtomCacheProvider>
                </IconsProvider>
              </CoreContext.Provider>
            </ConnectProvider>
          </ThemeProvider>
        </LayoutSizeProvider>
      </WindowSizeProvider>
    </Wrapper>
  );
});
