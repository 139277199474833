import { useCore } from 'logic/hooks/useCore';
import { memo } from 'react';
import { Warning } from './Warning';

export const UnsavedWarning: React.FC = memo(() => {
  const { useSelector } = useCore();
  const show = useSelector.selectShowUnsavedWarning();

  if (!show) {
    return null;
  }

  return <Warning />;
});
