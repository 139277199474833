import { AppAsyncViewName } from 'logic/async-views';
import queryString from 'query-string';
import { Chemin } from 'chemin';
import { Query } from '@lereacteur/apollo-common/dist/router/NavigationSlice';
import { ExtractMatch, RoutePattern } from '@lereacteur/apollo-common/dist/router/RoutePattern';
import { nni } from '@lereacteur/apollo-common/dist/logic/Invariant';
import { ADMIN_APP_ROUTES } from '@lereacteur/common/dist/constants/ADMIN_APP_ROUTES';
import { AppSliceState } from 'logic/slices/AppSlice';
import { SelectManager, Selector } from '@lereacteur/apollo-common/dist/connect/SelectManager';

export type Selectors = ReturnType<typeof createSelectors>;

export function createSelectors(selectManager: SelectManager) {
  function createAccessSelect<Input, Output>(
    access: (input: Input) => Output
  ): Selector<[Input], Output> {
    return (input) => selectManager.selectGlobal(access, () => access(input), [access(input)]);
  }

  function createSelectRoute<T>(route: Chemin<T>): Selector<[AppSliceState], T | false> {
    return (state: AppSliceState) => {
      const pathname = selectPathname(state);
      return selectManager.selectGlobal(route, () => route.matchExact(pathname), [pathname]);
    };
  }

  const selectLocation = createAccessSelect((state: AppSliceState) => state.location);
  const selectPathname = createAccessSelect((state: AppSliceState) => state.location.pathname);
  const selectRequested = createAccessSelect((state: AppSliceState) => state.navigation.requested);
  const selectNavigate = createAccessSelect((state: AppSliceState) => state.navigation.navigate);
  const selectCreateLinkProps = createAccessSelect(
    (state: AppSliceState) => state.navigation.createLinkProps
  );
  const selectShowUnsavedWarning = createAccessSelect(
    (state: AppSliceState) => state.unsavedWarningVisible
  );
  const selectUnsaved = createAccessSelect((state: AppSliceState) => state.unsaved.unsaved);
  const selectRegisterUnsaved = createAccessSelect(
    (state: AppSliceState) => state.unsaved.register
  );
  const selectCancelNavigation = createAccessSelect(
    (state: AppSliceState) => state.navigation.cancelRequestedLocation
  );
  const selectIgnoreUnsaved = createAccessSelect((state: AppSliceState) => state.unsaved.clearAll);
  const selectAsyncViews = createAccessSelect((state: AppSliceState) => state.asyncViews);
  const selectSessionFormMetadata = createAccessSelect(
    (state: AppSliceState) => state.createSessionMetaData
  );
  const selectInfosFirstAdminMetadata = createAccessSelect(
    (state: AppSliceState) => state.firstAdminMetaData
  );
  const selectInfosAcademy = createAccessSelect((state: AppSliceState) => state.academy);
  const selectPublicInfosAcademy = createAccessSelect(
    (state: AppSliceState) => state.academyPublicInfos
  );

  const selectRequestedPathname: Selector<[AppSliceState], string | null> = (state) => {
    const requested = selectRequested(state);
    return selectManager.selectGlobal(
      'SELECTED_PATHNAME',
      () => (requested ? requested.location.pathname : null),
      [requested]
    );
  };

  const selectParsedQuery = (state: AppSliceState): Query => {
    const location = selectLocation(state);
    return selectManager.selectGlobal(
      'PARSED_QUERY',
      () => {
        return queryString.parse(location.search, {
          arrayFormat: 'none',
          decode: true,
          parseBooleans: false,
          parseNumbers: false,
        }) as any; // we force type because we know there are no arrays !
      },
      [location.search]
    );
  };

  const selectParsedQueryKey = (state: AppSliceState, key: string): string | null => {
    const query = selectParsedQuery(state);
    return query[key] || null;
  };

  const selectLoginRouteMatch = createSelectRoute(ADMIN_APP_ROUTES.login);
  const selectAdminCourseRouteMatch = createSelectRoute(ADMIN_APP_ROUTES.courseInfos);
  const selectAdminCoursesRouteMatch = createSelectRoute(ADMIN_APP_ROUTES.courses);

  const selectAllRoutesRequested: Selector<
    [AppSliceState],
    ExtractMatch<typeof ADMIN_APP_ROUTES> | null
  > = (state: AppSliceState) => {
    const pathname = selectRequestedPathname(state);
    return selectManager.selectGlobal(
      'ROUTES_REQUESTED',
      () => {
        return pathname ? RoutePattern.matchNestedRouteObject(ADMIN_APP_ROUTES, pathname) : null;
      },
      [pathname]
    );
  };

  const selectAllRoutesMatch: Selector<[AppSliceState], ExtractMatch<typeof ADMIN_APP_ROUTES>> = (
    state: AppSliceState
  ) => {
    const pathname = selectPathname(state);
    return selectManager.selectGlobal(
      ADMIN_APP_ROUTES,
      () => {
        return RoutePattern.matchNestedRouteObject(ADMIN_APP_ROUTES, pathname);
      },
      [pathname]
    );
  };

  const selectCourse = (state: AppSliceState, courseId: string) =>
    selectManager.select(() => {
      return state.courseMap.getOrVoid(courseId);
    }, [courseId, state.courseMap]);

  const selectSession = (state: AppSliceState, sessionId: string) =>
    selectManager.select(() => {
      return state.sessionMap.getOrVoid(sessionId);
    }, [sessionId, state.sessionMap]);

  const selectSessionTree = (state: AppSliceState, sessionId: string) =>
    selectManager.select(() => {
      return state.sessionTreeMap.getOrVoid(sessionId);
    }, [sessionId, state.sessionTreeMap]);

  const selectAtomRes = (state: AppSliceState, atomId: string) =>
    selectManager.select(() => {
      return state.atomMap.getOrVoid(atomId);
    }, [atomId, state.atomMap]);

  const selectUser = (state: AppSliceState, userId: string) =>
    selectManager.select(() => {
      return state.userMap.getOrVoid(userId);
    }, [userId, state.userMap]);

  const selectUserSessions = (state: AppSliceState, userId: string) =>
    selectManager.select(() => {
      return state.userMapSessionsTables.getOrVoid(userId);
    }, [userId, state.userMapSessionsTables]);

  const selectCourseSessions = (state: AppSliceState, courseId: string) =>
    selectManager.select(() => {
      return state.courseMapSessions.getOrVoid(courseId);
    }, [courseId, state.courseMapSessions]);

  const selectAsyncViewState = (state: AppSliceState, view: AppAsyncViewName) =>
    selectManager.select(() => {
      return nni(state.asyncViews.state[view]);
    }, [view, state.asyncViews]);

  const selectMeUser = createAccessSelect((state: AppSliceState) => state.me);

  const selectIconsPaths = createAccessSelect((state: AppSliceState) => state.icons.ICONS_PATHS);

  const selectCoursesTable = createAccessSelect((state: AppSliceState) => state.coursesTable);
  const selectSessionsTable = createAccessSelect((state: AppSliceState) => state.sessionsTable);
  const selectUsersTable = createAccessSelect((state: AppSliceState) => state.usersTable);
  const selectAcademyTeamTable = createAccessSelect(
    (state: AppSliceState) => state.academyTeamTable
  );

  return {
    selectAcademyTeamTable,
    selectAdminCourseRouteMatch,
    selectAdminCoursesRouteMatch,
    selectAllRoutesMatch,
    selectAllRoutesRequested,
    selectAsyncViews,
    selectAsyncViewState,
    selectAtomRes,
    selectCancelNavigation,
    selectCourse,
    selectCoursesTable,
    selectCreateLinkProps,
    selectIconsPaths,
    selectIgnoreUnsaved,
    selectInfosAcademy,
    selectInfosFirstAdminMetadata,
    selectLocation,
    selectLoginRouteMatch,
    selectMeUser,
    selectNavigate,
    selectParsedQuery,
    selectParsedQueryKey,
    selectPathname,
    selectPublicInfosAcademy,
    selectRegisterUnsaved,
    selectRequested,
    selectRequestedPathname,
    selectSession,
    selectCourseSessions,
    selectSessionFormMetadata,
    selectSessionsTable,
    selectSessionTree,
    selectShowUnsavedWarning,
    selectUnsaved,
    selectUser,
    selectUsersTable,
    selectUserSessions,
  } as const;
}
