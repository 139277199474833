import { useChildren, createElement, useLayoutEffect, useMemo } from 'democrat';
import { RouterSlice } from '@lereacteur/apollo-common/dist/router/RouterSlice';
import { NavigationSlice } from '@lereacteur/apollo-common/dist/router/NavigationSlice';
import { IconsSlice } from './IconsSlices';
import { useShallowMemo } from '@lereacteur/apollo-common/dist/hooks/useShallowMemo';
import { AuthApi } from '@lereacteur/apollo-common/dist/api/AuthApi';
import { MetaDataApi } from '@lereacteur/apollo-common/dist/api/MetaDataApi';
import { MeResourceSlice } from '@lereacteur/apollo-common/dist/slices/MeResourceSlice';
import { AtomApi } from '@lereacteur/apollo-common/dist/api/AtomApi';
import { UnsavedSlice } from '@lereacteur/apollo-common/dist/slices/UnsavedSlice';
import { RealtimeClientManager } from 'logic/RealtimeClientManager';
import { UserApi } from '@lereacteur/apollo-common/dist/api/UserApi';
import { AcademyApi } from '@lereacteur/apollo-common/dist/api/AcademyApi';
import { CourseApi } from '@lereacteur/apollo-common/dist/api/CourseApi';
import { SessionApi } from '@lereacteur/apollo-common/dist/api/SessionApi';
import { TokenSlice } from '@lereacteur/apollo-common/dist/slices/TokenSlice';
import { AtomMapSlice } from './AtomMapSlice';
import { AcademySlice } from './AcademySlice';
import { AcademyPublicSlice } from './AcademyPublicSlice';
import { AcademyTeamSlice } from './AcademyTeamSlice';
import { UserMapSessionsSlice } from './UserMapSessionsSlice';
import { CourseMapSessionsSlice } from './CourseMapSessionsSlice';
import { CourseMapSlice } from './CourseMapSlice';
import { SessionMapSlice } from './SessionMapSlice';
import { UserMapSlice } from './UserMapSlice';
import { CoursesSlice } from './CoursesSlice';
import { UsersSlice } from './UsersSlice';
import { SessionsSlice } from './SessionsSlice';
import { CreateSessionMetaDataSlice } from './CreateSessionMetaDataSlice';
import { FirstAdminMetaDataSlice } from './FirstAdminMetaDataSlice';
import { AsyncViewsSlice } from './AsyncViewsSlice';
import { TokenStore } from '@lereacteur/apollo-common/dist/logic/TokenStore';
import { SessionTreeMapSlice } from './SessionTreeMapSlice';

export interface AppSliceProps {
  tokenStore: TokenStore;
  stabilizer: (state: AppSliceState) => void;
  authApi: AuthApi;
  atomApi: AtomApi;
  userApi: UserApi;
  academyApi: AcademyApi;
  courseApi: CourseApi;
  sessionApi: SessionApi;
  realtime: RealtimeClientManager;
  metaDataApi: MetaDataApi;
}

export type AppSliceState = ReturnType<typeof AppSlice>;

export function AppSlice({
  tokenStore,
  stabilizer,
  authApi,
  atomApi,
  realtime,
  academyApi,
  courseApi,
  sessionApi,
  userApi,
  metaDataApi,
}: AppSliceProps) {
  const { token, setToken, logout } = useChildren(createElement(TokenSlice, { tokenStore }));
  const router = useChildren(createElement(RouterSlice));
  const navigation = useChildren(createElement(NavigationSlice, { history: router.history }));
  const icons = useChildren(createElement(IconsSlice));
  const me = useChildren(createElement(MeResourceSlice, { authApi, token: token }));
  const createSessionMetaData = useChildren(
    createElement(CreateSessionMetaDataSlice, { metaDataApi, realtime })
  );
  const firstAdminMetaData = useChildren(createElement(FirstAdminMetaDataSlice, { metaDataApi }));

  const unsaved = useChildren(createElement(UnsavedSlice));
  const atomMap = useChildren(createElement(AtomMapSlice, { atomApi, realtime }));
  const academy = useChildren(createElement(AcademySlice, { academyApi, realtime }));
  const academyPublicInfos = useChildren(createElement(AcademyPublicSlice, { academyApi }));
  const courseMap = useChildren(createElement(CourseMapSlice, { courseApi, realtime }));
  const sessionMap = useChildren(createElement(SessionMapSlice, { sessionApi, realtime }));
  const userMap = useChildren(createElement(UserMapSlice, { userApi, realtime }));
  const userMapSessionsTables = useChildren(
    createElement(UserMapSessionsSlice, { sessionApi, realtime })
  );
  const coursesTable = useChildren(createElement(CoursesSlice, { courseApi, realtime }));
  const usersTable = useChildren(createElement(UsersSlice, { userApi, realtime }));
  const sessionsTable = useChildren(createElement(SessionsSlice, { sessionApi, realtime }));
  const academyTeamTable = useChildren(createElement(AcademyTeamSlice, { academyApi, realtime }));
  const asyncViews = useChildren(createElement(AsyncViewsSlice, {}));
  const sessionTreeMap = useChildren(createElement(SessionTreeMapSlice, { sessionApi, realtime }));
  const coursesSelect = useChildren(createElement(CoursesSlice, { courseApi, realtime }));
  const usersSelect = useChildren(createElement(UsersSlice, { userApi, realtime }));
  const courseMapSessions = useChildren(
    createElement(CourseMapSessionsSlice, { realtime, sessionApi })
  );

  const location = router.location;
  const initialLocation = router.initialLocation;

  const unsavedWarningVisible = useMemo(() => {
    return navigation.requested && unsaved.hasUnsaved;
  }, [navigation.requested, unsaved.hasUnsaved]);

  const setMeRequested = me.setRequested;

  useLayoutEffect(() => {
    setMeRequested(token !== null);
  }, [setMeRequested, token]);

  const state = useShallowMemo({
    academy,
    academyPublicInfos,
    academyTeamTable,
    asyncViews,
    atomMap,
    courseMap,
    coursesSelect,
    coursesTable,
    createSessionMetaData,
    firstAdminMetaData,
    icons,
    initialLocation,
    location,
    logout,
    me,
    navigation,
    sessionMap,
    sessionsTable,
    sessionTreeMap,
    setToken,
    token,
    unsaved,
    unsavedWarningVisible,
    userMap,
    userMapSessionsTables,
    usersSelect,
    usersTable,
    courseMapSessions,
  });

  useLayoutEffect(() => {
    stabilizer(state);
  });

  return state;
}
