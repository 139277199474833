import { AppAsyncViewsType } from 'logic/async-views';
import { RoutePattern } from '@lereacteur/apollo-common/dist/router/RoutePattern';
import { ADMIN_APP_ROUTES } from '@lereacteur/common/dist/constants/ADMIN_APP_ROUTES';

type Route = () => JSX.Element;

export function getRoute(pathname: string, AsyncViews: AppAsyncViewsType) {
  return RoutePattern.routePatternSwitch<Route>(
    pathname,
    [
      RoutePattern.createRoute(ADMIN_APP_ROUTES.createAdmin, () => () => (
        <AsyncViews.CreateAdmin />
      )),
      RoutePattern.createRoute(ADMIN_APP_ROUTES.login, () => () => <AsyncViews.Login />),
      RoutePattern.createRoute(ADMIN_APP_ROUTES.createAcademy, () => () => (
        <AsyncViews.CreateAcademy />
      )),
      RoutePattern.createRoute(ADMIN_APP_ROUTES.academyInfos, () => () => (
        <AsyncViews.AcademyDetailsInfos />
      )),
      RoutePattern.createRoute(ADMIN_APP_ROUTES.academyInfosEdit, () => () => (
        <AsyncViews.AcademyDetailsInfosEdit />
      )),
      RoutePattern.createRoute(ADMIN_APP_ROUTES.academyDetailsTheme, () => () => (
        <AsyncViews.AcademyDetailsTheme />
      )),
      RoutePattern.createRoute(ADMIN_APP_ROUTES.academyDetailsThemeEdit, () => () => (
        <AsyncViews.AcademyDetailsThemeEdit />
      )),
      RoutePattern.createRoute(ADMIN_APP_ROUTES.academyTeam, () => () => (
        <AsyncViews.AcademyTeam />
      )),
      RoutePattern.createRoute(ADMIN_APP_ROUTES.signupAdminFromInvite, () => () => (
        <AsyncViews.SignupAdminFromInvite />
      )),
      RoutePattern.createRoute(ADMIN_APP_ROUTES.academyMemberInvite, () => () => (
        <AsyncViews.InviteAcademyMember />
      )),
      RoutePattern.createRoute(ADMIN_APP_ROUTES.resetPassword, () => () => (
        <AsyncViews.PasswordReset />
      )),
      RoutePattern.createRoute(ADMIN_APP_ROUTES.requestResetPassword, () => () => (
        <AsyncViews.RequestResetPassword />
      )),
      RoutePattern.createRoute(ADMIN_APP_ROUTES.adminHome, () => () => <AsyncViews.AdminHome />),
      RoutePattern.createRoute(ADMIN_APP_ROUTES.courseInfos, ({ courseId }) => () => (
        <AsyncViews.CourseDetailsInfos courseId={courseId} />
      )),
      RoutePattern.createRoute(ADMIN_APP_ROUTES.courseSessionsList, ({ courseId }) => () => (
        <AsyncViews.CourseDetailsSessions courseId={courseId} />
      )),
      RoutePattern.createRoute(ADMIN_APP_ROUTES.courseEdit, ({ courseId }) => () => (
        <AsyncViews.CourseDetailsEdit courseId={courseId} />
      )),
      RoutePattern.createRoute(ADMIN_APP_ROUTES.courses, () => () => (
        <AsyncViews.Courses showAddButton={true} />
      )),
      RoutePattern.createRoute(ADMIN_APP_ROUTES.createCourse, () => () => (
        <AsyncViews.CreateCourse />
      )),
      RoutePattern.createRoute(ADMIN_APP_ROUTES.users, () => () => <AsyncViews.Users />),
      RoutePattern.createRoute(ADMIN_APP_ROUTES.userInfos, ({ userId }) => () => (
        <AsyncViews.UserDetailsInfos userId={userId} />
      )),
      RoutePattern.createRoute(ADMIN_APP_ROUTES.userInfosLogin, ({ userId }) => () => (
        <AsyncViews.UserDetailsLogin userId={userId} />
      )),
      RoutePattern.createRoute(ADMIN_APP_ROUTES.userEdit, ({ userId }) => () => (
        <AsyncViews.UserDetailsEdit userId={userId} />
      )),
      RoutePattern.createRoute(ADMIN_APP_ROUTES.userLoginEdit, ({ userId }) => () => (
        <AsyncViews.UserDetailsLoginEdit userId={userId} />
      )),
      RoutePattern.createRoute(ADMIN_APP_ROUTES.userSessions, ({ userId }) => () => (
        <AsyncViews.UserDetailsSessions userId={userId} />
      )),
      RoutePattern.createRoute(ADMIN_APP_ROUTES.usersInvite, () => () => <AsyncViews.InviteUser />),
      RoutePattern.createRoute(ADMIN_APP_ROUTES.sessions, () => () => (
        <AsyncViews.Sessions showAddButton={true} />
      )),
      RoutePattern.createRoute(ADMIN_APP_ROUTES.createSession, () => () => (
        <AsyncViews.CreateSession />
      )),
      RoutePattern.createRoute(ADMIN_APP_ROUTES.sessionInfos, ({ sessionId }) => () => (
        <AsyncViews.SessionDetailsInfos sessionId={sessionId} />
      )),
      RoutePattern.createRoute(ADMIN_APP_ROUTES.sessionChecklist, ({ sessionId }) => () => (
        <AsyncViews.SessionDetailsChecklist sessionId={sessionId} />
      )),
      RoutePattern.createRoute(ADMIN_APP_ROUTES.sessionCalendar, ({ sessionId }) => () => (
        <AsyncViews.SessionDetailsCalendar sessionId={sessionId} />
      )),
      RoutePattern.createRoute(ADMIN_APP_ROUTES.sessionEdit, ({ sessionId }) => () => (
        <AsyncViews.SessionDetailsEdit sessionId={sessionId} />
      )),
      RoutePattern.createRoute(ADMIN_APP_ROUTES.sessionStudents, ({ sessionId }) => () => (
        <AsyncViews.SessionDetailsStudents sessionId={sessionId} />
      )),
      RoutePattern.createRoute(ADMIN_APP_ROUTES.sessionAddStudents, ({ sessionId }) => () => (
        <AsyncViews.SessionDetailsAddStudents sessionId={sessionId} />
      )),
      RoutePattern.createRoute(ADMIN_APP_ROUTES.sessionContent, ({ sessionId }) => () => (
        <AsyncViews.SessionContent sessionId={sessionId} atomId={null} />
      )),
      RoutePattern.createRoute(
        ADMIN_APP_ROUTES.sessionContentAtom,
        ({ sessionId, atomId }) => () => (
          <AsyncViews.SessionContent sessionId={sessionId} atomId={atomId} />
        )
      ),
      RoutePattern.createRoute(ADMIN_APP_ROUTES.sessionInviteUser, ({ sessionId }) => () => (
        <AsyncViews.AdminInviteUser sessionId={sessionId} />
      )),
      RoutePattern.createRoute(ADMIN_APP_ROUTES.modules, () => () => <AsyncViews.Modules />),
    ],
    () => () => <AsyncViews.AdminNotFound />
  );
}
