import { createResourceMapSlice } from '@lereacteur/apollo-common/dist/slices/ResourceMapSlice';
import { useChildren, useLayoutEffect } from 'democrat';
import { AtomApi } from '@lereacteur/apollo-common/dist/api/AtomApi';
import { RealtimeClientManager } from 'logic/RealtimeClientManager';
import { Atom } from '@lereacteur/common/dist/routes/atom';
import { useLatestRef } from 'logic/hooks/useLatestRef';
import { AtomUtils } from '@lereacteur/common/dist/utils/AtomUtils';

interface Props {
  atomApi: AtomApi;
  realtime: RealtimeClientManager;
}

export function AtomMapSlice({ atomApi, realtime }: Props) {
  const atoms = useChildren(
    createResourceMapSlice<string, Atom>({
      name: 'Atom',
      fetchData: atomApi.getById,
    })
  );

  const atomsRef = useLatestRef(atoms);

  useLayoutEffect(() => {
    return realtime.subscribe((message) => {
      if (message.type === 'AtomMutation') {
        const res = atomsRef.current.resources.get(message.id);
        if (res && res.requested) {
          const atom = res.dataOrNull;
          if (!atom) {
            return;
          }
          res.updateData((prev) => AtomUtils.applyMutation(prev, message.mutation));
        }
      }
    });
  }, [atomsRef, realtime]);

  return atoms;
}
