import { FullscreenLoader } from '@lereacteur/apollo-common/dist/components/FullscreenLoader';
import { useCore } from 'logic/hooks/useCore';
import { AsyncViewStatus } from 'logic/slices/AsyncViewsSlice';
import { nn } from '@lereacteur/apollo-common/dist/logic/Invariant';
import { usePreventFlashing } from '@lereacteur/apollo-common/dist/hooks/usePreventFlashing';
import { Envs } from 'logic/Envs';
import { theme, Theme, ThemeProvider } from '@lereacteur/apollo-common/dist/style';
import { useCallback, useMemo } from 'react';

interface AsyncViewProps {
  loaderComponent?: React.ReactElement;
}

export const AsyncViewLoader = {
  create: createAsyncViewLoader,
  createFromObject: createAsyncViewLoaderFromObject,
};

interface BaseViews {
  [key: string]: () => Promise<React.FC<any>>;
}

type WithAsyncViewProps<P> = P extends React.FC<infer Props> ? React.FC<Props & AsyncViewProps> : P;

export type ValidateAsyncImports<T extends BaseViews> = T;

export type AsyncViewsType<Views extends BaseViews> = {
  [K in keyof Views]: Views[K] extends () => Promise<infer P> ? WithAsyncViewProps<P> : never;
};

function createAsyncViewLoaderFromObject<Views extends BaseViews>(
  views: Views
): AsyncViewsType<Views> {
  return Object.keys(views).reduce((acc, key) => {
    acc[key] = AsyncViewLoader.create(key as any);
    return acc;
  }, {} as any) as any;
}

function createAsyncViewLoader<Views extends BaseViews, K extends keyof Views>(
  name: K
): AsyncViewsType<Views>[K] {
  const AsyncViewLoader: React.FC<any> = ({ loaderComponent, ...props }: AsyncViewProps) => {
    const { useSelector } = useCore();
    const asyncViews = useSelector.selectAsyncViews();
    const meRes = useSelector.selectMeUser();
    const meUser = meRes.dataOrNull;
    const primaryColor = meUser?.academyInfos?.primaryColor.background;
    const primaryTextColor = meUser?.academyInfos?.primaryColor.text;
    const primarySelectedTextColor = meUser?.academyInfos?.primaryColor.selectedText;

    const themeOverride = useMemo(
      (): Theme => ({
        ...theme,
        colors: {
          ...theme.colors,
          brandViolet: primaryColor ?? theme.colors.brandViolet,
          lightBrandViolet: primaryTextColor ?? theme.colors.lightBrandViolet,
          orange: primarySelectedTextColor ?? theme.colors.orange,
        },
      }),
      [primaryColor, primaryTextColor, primarySelectedTextColor]
    );
    const rawState = useSelector.selectAsyncViewState(name as any);

    const isPending = useCallback((s) => s.status === AsyncViewStatus.PENDING, []);
    const state = usePreventFlashing(rawState, isPending);

    if (state.status === 'RESOLVED') {
      const LoadedComponent = nn(state.data);
      return <LoadedComponent {...(props as any)} />;
    }
    if (state.status === 'VOID') {
      return (
        <div>
          <p>
            <code>{name}</code> not loaded !
          </p>
          <button
            onClick={() => {
              asyncViews.request(name as any);
            }}
          >
            Load Component
          </button>
        </div>
      );
    }
    if (state.status === 'PENDING') {
      return (
        loaderComponent || (
          <ThemeProvider theme={themeOverride}>
            <FullscreenLoader devInfo={`AsyncView ${name}`} isDev={Envs.IS_DEV} />
          </ThemeProvider>
        )
      );
    }

    return <div>Error</div>;
  };

  AsyncViewLoader.displayName = `AsyncView.${name}`;

  return AsyncViewLoader as any;
}
