import { useResourceWithParams } from '@lereacteur/apollo-common/dist/hooks/useResourceWithParams';
import { useLayoutEffect } from 'democrat';
import { AcademyApi } from '@lereacteur/apollo-common/dist/api/AcademyApi';
import { RealtimeClientManager } from 'logic/RealtimeClientManager';
import { useLatestRef } from 'logic/hooks/useLatestRef';
import {
  AcademyTeamListParams,
  AcademyTeamListResult,
} from '@lereacteur/common/dist/routes/academy';

interface Props {
  academyApi: AcademyApi;
  realtime: RealtimeClientManager;
}

export function AcademyTeamSlice({ academyApi, realtime }: Props) {
  const academyTeam = useResourceWithParams<AcademyTeamListResult, Required<AcademyTeamListParams>>(
    {
      name: 'AcademyTeam',
      fetchData: academyApi.teamList,
      initialParams: {
        itemsPerPage: 10,
        pageIndex: 0,
        sort: null,
        order: null,
        search: null,
        sessions: null,
        role: 'admin',
      },
    }
  );

  const academyTeamRef = useLatestRef(academyTeam);

  useLayoutEffect(() => {
    return realtime.subscribeOutdate((routes) => {
      if (routes.academyTeam) {
        if (academyTeamRef.current.requested) {
          academyTeamRef.current.outdate();
        }
      }
    });
  }, [academyTeamRef, realtime]);

  return academyTeam;
}
