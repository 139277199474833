import { useResourceWithParams } from '@lereacteur/apollo-common/dist/hooks/useResourceWithParams';
import { useLayoutEffect } from 'democrat';
import { SessionApi } from '@lereacteur/apollo-common/dist/api/SessionApi';
import { RealtimeClientManager } from 'logic/RealtimeClientManager';
import { useLatestRef } from 'logic/hooks/useLatestRef';
import { SessionsListResult, SessionsListParams } from '@lereacteur/common/dist/routes/session';

interface Props {
  sessionApi: SessionApi;
  realtime: RealtimeClientManager;
}

export function SessionsSlice({ sessionApi, realtime }: Props) {
  const sessions = useResourceWithParams<SessionsListResult, Required<SessionsListParams>>({
    name: 'Sessions',
    fetchData: sessionApi.list,
    initialParams: {
      itemsPerPage: 50,
      pageIndex: 0,
      sort: 'startAt',
      order: 'DESC',
      userId: null,
      courseId: null,
      startAt: null,
      endAt: null,
      reference: null,
      search: '',
    },
  });

  const sessionsRef = useLatestRef(sessions);

  useLayoutEffect(() => {
    return realtime.subscribeOutdate((routes) => {
      if (routes.sessions) {
        if (sessionsRef.current.requested) {
          sessionsRef.current.outdate();
        }
      }
    });
  }, [sessionsRef, realtime]);

  return sessions;
}
