import { getRoute } from 'routes';
import { useLocation } from 'logic/hooks/useLocation';
import { useCore } from 'logic/hooks/useCore';
import Helmet from 'react-helmet';
import { memo } from 'react';

interface RouterProps {}

export const Router: React.FC<RouterProps> = memo<RouterProps>(function Router() {
  const location = useLocation();
  const { AsyncViews, useSelector } = useCore();
  const meRes = useSelector.selectMeUser();
  const meUser = meRes.dataOrNull;
  let logoSquare = meUser?.academyInfos?.logoSquare?.url;
  const routeResult = getRoute(location.pathname, AsyncViews);

  const route = routeResult.exec();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="shortcut icon" id="favicon" href={logoSquare} />
      </Helmet>
      {route()}
    </>
  );
});
