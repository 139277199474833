import { createResourceMapSlice } from '@lereacteur/apollo-common/dist/slices/ResourceMapSlice';
import { useChildren, useLayoutEffect } from 'democrat';
import { UserApi } from '@lereacteur/apollo-common/dist/api/UserApi';
import { RealtimeClientManager } from 'logic/RealtimeClientManager';
import { useLatestRef } from '../hooks/useLatestRef';
import { User } from '@lereacteur/common/dist/routes/user';

interface Props {
  userApi: UserApi;
  realtime: RealtimeClientManager;
}

export function UserMapSlice({ userApi, realtime }: Props) {
  const users = useChildren(
    createResourceMapSlice<string, User>({
      name: 'User',
      fetchData: userApi.getById,
    })
  );

  const usersRef = useLatestRef(users);

  useLayoutEffect(() => {
    return realtime.subscribeOutdate((routes: any) => {
      if (routes.user) {
        const res = usersRef.current.resources.get(routes.user.id);
        if (res && res.requested) {
          res.outdate();
        }
      }
    });
  }, [usersRef, realtime]);

  return users;
}
