import { Overlay } from '@lereacteur/apollo-common/dist/components/Overlay';
import { useCore } from 'logic/hooks/useCore';
import { Styled, Box, Flex } from '@lereacteur/apollo-common/dist/style';
import { Button } from '@lereacteur/apollo-common/dist/components/Button';
import { t } from 'logic/i18n';
import { memo } from 'react';

export const Warning: React.FC = memo(() => {
  const { useSelector } = useCore();
  // const unsaved = useSelector.selectUnsaved();
  const cancelNav = useSelector.selectCancelNavigation();
  const ignoreUnsaved = useSelector.selectIgnoreUnsaved();

  return (
    <Overlay>
      <Styled.div
        zs={[
          Box.fill(),
          Flex.center(),
          {
            background: 'rgba(0, 0, 0, 0.8)',
          },
        ]}
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            cancelNav();
          }
        }}
      >
        <Styled.div
          zs={[
            (theme) => ({
              background: theme.colors.white,
              borderRadius: theme.size.cardRadius,
              padding: 30,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }),
          ]}
        >
          <Styled.h2
            zs={[
              {
                margin: 0,
                textAlign: 'center',
                marginBottom: '2rem',
              },
            ]}
          >
            {t("Vos modifications n'ont pas été enregistrées")}
          </Styled.h2>
          {/* <div>
            <ul>
              {unsaved.map(item => (
                <li key={item.id}>{item.message}</li>
              ))}
            </ul>
          </div> */}
          <Styled.div zs={[Flex.center()]}>
            <Button onClick={cancelNav} style={{ marginRight: '1rem' }}>
              {t('Continuer de modifier')}
            </Button>
            <Button onClick={ignoreUnsaved}> {t('Abandonner les changements en cours')}</Button>
          </Styled.div>
        </Styled.div>
      </Styled.div>
    </Overlay>
  );
});
