import {
  ASYNC_VIEWS,
  AppAsyncViewName,
  AppAsyncViewsType,
  AppAsyncViewsValue,
} from 'logic/async-views';
import { useState, useRef, useCallback, useMemo } from 'democrat';
import { nni } from '@lereacteur/apollo-common/dist/logic/Invariant';
import { RandomDelay } from '@lereacteur/apollo-common/dist/logic/RandomDelay';
import produce from 'immer';
import { wait } from '@lereacteur/common/dist/utils/utils';

const IS_ASYNC_VIEWS_STATE = Symbol('IS_ASYNC_VIEWS_DEP');

export enum AsyncViewStatus {
  VOID = 'VOID',
  PENDING = 'PENDING',
  RESOLVED = 'RESOLVED',
  REJECTED = 'REJECTED',
}

export type AsyncViewState<K extends AppAsyncViewName = AppAsyncViewName> = {
  status: AsyncViewStatus;
  name: AppAsyncViewName;
  data: null | AppAsyncViewsType[K];
  error: unknown | null;
  [IS_ASYNC_VIEWS_STATE]: true;
};

export type AsyncViewsStoreState = { [K in AppAsyncViewName]: AsyncViewState<AppAsyncViewName> };

export function isAsyncViewState(maybe: any): maybe is AsyncViewState<AppAsyncViewName> {
  return maybe && maybe[IS_ASYNC_VIEWS_STATE];
}

export type AsyncViewsSlice = ReturnType<typeof AsyncViewsSlice>;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export function AsyncViewsSlice() {
  const [state, setState] = useState<AsyncViewsStoreState>(() => {
    return Object.keys(ASYNC_VIEWS).reduce<AsyncViewsStoreState>((acc, name) => {
      acc[name as AppAsyncViewName] = {
        [IS_ASYNC_VIEWS_STATE]: true,
        name: name as AppAsyncViewName,
        status: AsyncViewStatus.VOID,
        data: null,
        error: null,
      };
      return acc;
    }, {} as any);
  });

  const stateRef = useRef(state);
  stateRef.current = state;

  const request = useCallback(async (name: AppAsyncViewName) => {
    const currentState = stateRef.current[name];
    if (!currentState || currentState.status !== AsyncViewStatus.VOID) {
      return;
    }
    setState((prev) =>
      produce(prev, (draft) => {
        nni(draft[name]).status = AsyncViewStatus.PENDING;
      })
    );
    const view: Promise<AppAsyncViewsValue> = ASYNC_VIEWS[name]();
    view
      .then(async (r) => {
        const delay = RandomDelay.getDelay();
        await wait(delay);
        return r;
      })
      .then((Component) => {
        setState((prev) =>
          produce(prev, (draft) => {
            nni(draft[name]).status = AsyncViewStatus.RESOLVED;
            nni(draft[name]).data = Component;
          })
        );
      })
      .catch((error) => {
        setState((prev) =>
          produce(prev, (draft) => {
            nni(draft[name]).status = AsyncViewStatus.REJECTED;
            nni(draft[name]).error = error;
          })
        );
      });
  }, []);

  return useMemo(() => ({ state, request }), [request, state]);
}
